<template>
  <b-card
    img-top
    no-body
  >
    <b-card-img
      :src="course.image"
      style="aspect-ratio: 16 / 9;"
    />
    <b-card-body class="course-card">
      <b-card-title class="course-title">
        {{ course.title }}
      </b-card-title>
      <b-card-text class="ellipsis course-desc">
        {{ course.desc }}
      </b-card-text>

      <div class="d-flex flex-column my-1">
        <span class="price">Rp. {{ formatPrice(course.price) }}</span>
        <span class="price-strike">Rp. {{ formatPrice(course.base_price) }}</span>
      </div>

      <div class="d-flex justify-content-center">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="w-100"
          variant="secondary"
          :to="{
            name: 'pages.trainings.show',
            params: {
              trainingId: course.id
            }
          }"
        >
          Info Kelas
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardImg,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardImg,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    course: {
      type: Object,
      default: () => ({
        title: 'Kelas AR (Augmented Reality)',
        desc:
          'Kelas AR (Augmented Reality) adalah kelas yang membahas tentang penggunaan teknologi AR dalam pembelajaran. Dalam kelas ini, peserta akan belajar bagaimana cara menggunakan teknologi AR dalam pembelajaran.',
        image: require('@/assets/images/slider/06.jpg'),
        contact_url: 'https://wa.me/6281212345678',
        url: 'https://www.tekov.id',
        price: 150000,
        base_price: 200000,
      }),
    },
  },
  methods: {
    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
}
</script>

<style lang="scss" scoped>
$secondary: #fb6514;

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  min-height: 62.97px;
}

.price {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  color: $secondary;
}

.price-strike {
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: line-through;
}

.bg-secondary {
  background-color: $secondary !important;
}

.border-secondary {
  border-color: $secondary !important;
}

.course-title {
  height: 4rem;
}
.course-desc {
  height: 2rem;
}
</style>
