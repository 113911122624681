<template>
  <div>
    <b-row v-if="!data.id">
      <b-col class="d-flex justify-content-center mt-4">
        <div class="text-center mt-4">
          <b-spinner variant="primary" class="mr-1" />
          <p class="mt-1">
            Memuat Data Detail Pembayaran
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col lg="6">
        <course-card :course="course" />
      </b-col>
      <b-col lg="6">
        <b-card class="card mb-1" title="Data Detail Pembayaran">
          <div class="mt-2">
            <b-row>
              <b-col sm="12" class="mb-1" v-if="data.transaction_promo && data.transaction_promo.promo">
                <b-form-group>
                  <label class="d-block">Kode Promo:</label>
                  <h4>{{ data.transaction_promo.promo.code }}</h4>
                </b-form-group>
              </b-col>

              <b-col sm="4" class="mb-1">
                <b-form-group>
                  <label class="d-block">Harga:</label>
                  <h4>{{ formatRupiah(price) }}</h4>
                </b-form-group>
              </b-col>

              <b-col sm="4" class="mb-1" v-if="discount">
                <b-form-group>
                  <label class="d-block">Diskon:</label>
                  <h4>{{ formatRupiah(discount) }}</h4>
                </b-form-group>
              </b-col>

              <b-col sm="4" class="mb-1">
                <b-form-group>
                  <label class="d-block">Total Bayar:</label>
                  <h4>{{ formatRupiah(payment) }}</h4>
                </b-form-group>
              </b-col>

              <b-col sm="12" class="mb-1">
                <b-form-group>
                  <label class="d-block">Status:</label>
                  <b-badge :variant="data.status | statusClass" class="small-gap-right">
                    {{ data.status }}
                  </b-badge>
                </b-form-group>
              </b-col>
            </b-row>
            <div>
              <b-button v-if="data.status == 'Pending' || data.status == 'Menunggu Pembayaran'" variant="success" class="float-right"
                @click="verifyTransaction('Sukses')">
                <div class="d-flex align-items-center">
                  <b-spinner v-show="isLoading" class="mr-1" variant="light" small />
                  Verifikasi Langsung
                </div>
              </b-button>
            </div>
            <div>
              <b-button v-if="data.status == 'Pending' || data.status == 'Menunggu Pembayaran'" variant="danger" class="mr-1 float-right"
                @click="verifyTransaction('Gagal')">
                <div class="d-flex align-items-center">
                  <b-spinner v-show="isLoading" class="mr-1" variant="light" small />
                  Tolak
                </div>
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
  
<script>
// Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BFormGroup,
  BFormInput,
  BSpinner,
  BBadge,
  BModal,
  BForm
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { reqGetTransaction, reqUpdateTransaction } from "@/api/admin/transaction";
import { checkAuthorizeRole } from "@/auth/utils";
import { pascalCase, avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import CourseCard from '@/views/tekov/landingpage/components/CourseCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormGroup,
    BFormInput,
    BSpinner,
    BBadge,
    BModal,
    BForm,
    CourseCard
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      data: {},
      user: JSON.parse(localStorage.getItem('userData')),
      form: {
        promo_code: ''
      },
      errors: {}
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    price() {
      return this.data.amount
    },
    promoObtain() {
      return this.data.amount
    },
    discount() {
      return this.data.transaction_promo ? this.data.transaction_promo.obtain : 0
    },
    payment() {
      return this.price - this.discount
    },
    course() {
      if (this.data.for)
      return {
        id: this.data.for.id,
        title: this.data.for.title,
        desc: this.data.for.description,
        image: this.data.for.image,
        contact_url: 'https://wa.me/6281212345678',
        url: 'https://www.tekov.id',
        price: this.data.for.price * (100 - this.data.for.discount) / 100,
        base_price: this.data.for.price,
      }
      return {}
    }
  },
  filters: {
    statusClass(status) {
      if (status == "Menunggu Persetujuan") {
        return "secondary";
      }
      if (status == "Diterima") {
        return "success";
      }
      return "danger";
    },
  },
  methods: {
    checkAuthorizeRole,
    avatarText,
    formatRupiah(value) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(value)
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      });
    },
    verifyTransaction(value) {
      reqUpdateTransaction(this.$route.params.id, { status: value })
        .then((response) => {
          this.showToast(
            "success",
            "CheckIcon",
            "Berhasil",
            response.data.data.message || "Berhasil mengubah status pembayaran"
          );
          this.getData()
        })
        .catch(error => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Gagal",
            error.response.data.message || "Gagal mengubah status pembayaran"
          );
        })
    },
    getData() {
      this.isLoading = true;
      reqGetTransaction(this.$route.params.id)
        .then((response) => {
          this.data = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Gagal",
            error.response.data.message || "User Gagal Ditambahkan"
          );
        });
    },
    mapErrorsField(errors) {
      const mapErrors = [];
      const fields = {
        name: "Nama Pelatihan",
        purpose: "Tujuan Pelatihan",
        description: "Deskripsi Pelatihan",
      };
      for (let field in errors) {
        mapErrors[fields[field] ? fields[field] : field] = errors[field];
      }
      return mapErrors;
    },
  },
};
</script>
  